export enum DataShowFormat {
  PRIMENG_ISO_DATE = 'mm/dd/yy',
  PRIMENG_ISO_DATETIME = 'mm/dd/yy HH:mm',
  PRIMENG_ISO_DATETIME_SEC = 'mm/dd/yy HH:mm:ss',
  PRIMENG_EN_DATE = 'mm/dd/yy',
  PRIMENG_EN_DATETIME = 'mm/dd/yy HH:mm',
  PRIMENG_EN_DATETIME_SEC = 'mm/dd/yy HH:mm:ss',
  PRIMENG_VI_DATE = 'dd/mm/yy',
  PRIMENG_VI_DATETIME = 'dd/mm/yy HH:mm',
  PRIMENG_VI_DATETIME_SEC = 'dd/mm/yy HH:mm:ss',

  ISO_DATE = 'MM/dd/yyyy',
  ISO_DATETIME = 'MM/dd/yyyy HH:mm',
  ISO_DATETIME_SEC = 'MM/dd/yyyy HH:mm:ss',
  EN_DATE = 'MM/dd/yyyy',
  EN_DATETIME = 'MM/dd/yyyy HH:mm',
  EN_DATETIME_SEC = 'MM/dd/yyyy HH:mm:ss',
  VI_DATE = 'dd/MM/yyyy',
  VI_DATETIME = 'dd/MM/yyyy HH:mm',
  VI_DATETIME_SEC = 'dd/MM/yyyy HH:mm:ss',
}


export enum DateFilterType {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  THIS_WEEK = 'this-week',
  LAST_WEEK = 'last-week',
  THIS_MONTH = 'this-month',
  LAST_MONTH = 'last-month',
  THIS_YEAR = 'this-year',
  LAST_YEAR = 'last-year',
  ALL_TIME = 'all-time',
}
