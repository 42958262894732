import { Injectable } from '@angular/core';
import { DataShowFormat as DSF } from '@core/enum/date';

@Injectable({
  providedIn: 'root'
})
export class TimeHandler {
  getReceivedDate(value: string) {
    let localOffset = Date.parse(value);
    let zeroUTCOffset = localOffset + new Date().getTimezoneOffset() * 60000;
    return new Date(zeroUTCOffset);
  }

  getDateValue(value: Date, isPrimeNG: boolean = false, locale: string = ''): string {
    switch (this.formatDate(isPrimeNG, locale)) {
      case DSF.ISO_DATE:
      case DSF.PRIMENG_ISO_DATE:
        return this.getISODateString(value);
      case DSF.VI_DATE:
      case DSF.PRIMENG_VI_DATE:
        return this.getVIDateString(value);
      case DSF.ISO_DATETIME:
      case DSF.PRIMENG_ISO_DATETIME:
      // Not implemented yet
      case DSF.VI_DATETIME:
      case DSF.PRIMENG_VI_DATETIME:
      // Not implemented yet
      default:
        return this.getISODateString(value);
    }
  }

  formatDate(isPrimeNG: boolean = false, locale: string = ''): string {
    if (isPrimeNG) {
      switch (locale) {
        case 'en':
          return DSF.PRIMENG_ISO_DATE;
        case 'vi':
          return DSF.PRIMENG_VI_DATE;
        default:
          return DSF.PRIMENG_ISO_DATE;
      }
    } else {
      switch (locale) {
        case 'en':
          return DSF.ISO_DATE;
        case 'vi':
          return DSF.VI_DATE;
        default:
          return DSF.ISO_DATE;
      }
    }
  }

  formatDateTime(isPrimeNG: boolean = false, locale: string = ''): string {
    if (isPrimeNG) {
      switch (locale) {
        case 'en':
          return DSF.PRIMENG_ISO_DATETIME;
        case 'vi':
          return DSF.PRIMENG_VI_DATETIME;
        default:
          return DSF.PRIMENG_ISO_DATETIME;
      }
    } else {
      switch (locale) {
        case 'en':
          return DSF.ISO_DATETIME;
        case 'vi':
          return DSF.VI_DATETIME;
        default:
          return DSF.ISO_DATETIME;
      }
    }
  }

  formatDateTimeSec(isPrimeNG: boolean = false, locale: string = ''): string {
    if (isPrimeNG) {
      switch (locale) {
        case 'en':
          return DSF.PRIMENG_ISO_DATETIME_SEC;
        case 'vi':
          return DSF.PRIMENG_VI_DATETIME_SEC;
        default:
          return DSF.PRIMENG_ISO_DATETIME_SEC;
      }
    } else {
      switch (locale) {
        case 'en':
          return DSF.ISO_DATETIME_SEC;
        case 'vi':
          return DSF.VI_DATETIME_SEC;
        default:
          return DSF.ISO_DATETIME_SEC;
      }
    }
  }

  /**
   * Change timezone to UTC +0 without changing the time
   *
   * @returns ISO format, return "_" error occurred
   */
  getISOValue(value: Date): string {
    let date = new Date(value);
    let dateTime = new Date();
    let utcDate = Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      dateTime.getHours(),
      dateTime.getMinutes(),
      dateTime.getSeconds()
    );
    return new Date(utcDate).toISOString();
  }

  /**
   * Change timezone to UTC +0 without time
   *
   * @returns ISO format, return current local date if error occurred
   */
  getISONoTimeZValue(value: Date) {
    let date = new Date(value);
    let utcDate = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
    return new Date(utcDate).toISOString();
  }

  /**
   * Get date as string type
   *
   * @returns "yy-mm-dd" format
   */
  getISODateString(value: Date): string {
    let date = new Date(value);
    return this.twoDigit(date.getMonth() + 1) + '/' + this.twoDigit(date.getDate()) + '/' + date.getFullYear();
  }

  /**
   * Get date as string type
   *
   * @returns "dd-mm-yy" format
   */
  getVIDateString(value: Date): string {
    let date = new Date(value);
    return this.twoDigit(date.getDate()) + '/' + this.twoDigit(date.getMonth() + 1) + '/' + date.getFullYear();
  }

  /**
   * Convert ISO format to string type
   * @param value a string in ISO datetime format
   * @returns "yy/mm/dd" format, UTC +0, "--/--/--" if failed
   */
  parseISODateString(value: string): string {
    if (!Date.parse(value)) return '--/--/--';
    let date = new Date(value);
    return date.getUTCFullYear() + '/' + this.twoDigit(date.getUTCMonth() + 1) + '/' + this.twoDigit(date.getUTCDate());
  }

  /**
   * Convert ISO format to string type
   * @param isoValue a string in ISO datetime format
   * @returns "dd-mm-yy" format, UTC +0, "--/--/--" if failed
   */
  parseVIDateString(isoValue: string): string {
    if (!Date.parse(isoValue)) return '--/--/--';
    let date = new Date(isoValue);
    return this.twoDigit(date.getUTCDate()) + '/' + this.twoDigit(date.getUTCMonth() + 1) + '/' + date.getUTCFullYear();
  }

  /**
   * Convert ISO format to string type, UTC +0
   * @param isoValue a string in ISO datetime format
   * @returns "yy/mm/dd hh:mm:ss" format, "--/--/--" if failed
   */
  parseISOString(isoValue: string): string {
    if (!Date.parse(isoValue)) return '--/--/--';
    let date = new Date(isoValue);
    return (
      date.getUTCFullYear() +
      '/' +
      this.twoDigit(date.getUTCMonth() + 1) +
      '/' +
      this.twoDigit(date.getUTCDate()) +
      ' ' +
      this.twoDigit(date.getUTCHours()) +
      ':' +
      this.twoDigit(date.getUTCMinutes()) +
      ':' +
      this.twoDigit(date.getUTCSeconds())
    );
  }

  /**
   * Convert ISO format to string type, UTC +0
   * @param isoValue a string in ISO datetime format
   * @returns "dd-mm-yy hh:mm:ss" format, "--/--/--" if failed
   */
  parseVIString(isoValue: string): string {
    if (!Date.parse(isoValue)) return '--/--/--';
    let date = new Date(isoValue);
    return (
      this.twoDigit(date.getUTCDate()) +
      '/' +
      this.twoDigit(date.getUTCMonth() + 1) +
      '/' +
      date.getUTCFullYear() +
      ' ' +
      this.twoDigit(date.getUTCHours()) +
      ':' +
      this.twoDigit(date.getUTCMinutes()) +
      ':' +
      this.twoDigit(date.getUTCSeconds())
    );
  }

  /**
   * Convert ISO format to string type, local date
   * @param isoValue a string in ISO datetime format
   * @returns "yy/mm/dd" format in local date, "--/--/--" if failed
   */
  parseLISODateString(isoValue: string): string {
    if (!Date.parse(isoValue)) return '--/--/--';
    let date = new Date(isoValue);
    return date.getFullYear() + '/' + this.twoDigit(date.getMonth() + 1) + '/' + this.twoDigit(date.getDate());
  }

  /**
   * Convert ISO format to string type, local date
   * @param isoValue a string in ISO datetime format
   * @returns "dd-mm-yy" format in local date, "--/--/--" if failed
   */
  parseLVIDateString(isoValue: string): string {
    if (!Date.parse(isoValue)) return '--/--/--';
    let date = new Date(isoValue);
    return this.twoDigit(date.getDate()) + '/' + this.twoDigit(date.getMonth() + 1) + '/' + date.getFullYear();
  }

  /**
   * Convert ISO format to string type, local time zone
   * @param isoValue a string in ISO datetime format
   * @returns "yy/mm/dd hh:mm:ss" format in local time zone, "--/--/--" if failed
   */
  parseLISOString(isoValue: string): string {
    if (!Date.parse(isoValue)) return '--/--/--';
    let date = new Date(isoValue);
    return (
      date.getFullYear() +
      '/' +
      this.twoDigit(date.getMonth() + 1) +
      '/' +
      this.twoDigit(date.getDate()) +
      ' ' +
      this.twoDigit(date.getHours()) +
      ':' +
      this.twoDigit(date.getMinutes()) +
      ':' +
      this.twoDigit(date.getSeconds())
    );
  }

  /**
   * Convert ISO format to string type, local time zone
   * @param isoValue a string in ISO datetime format
   * @returns "dd-mm-yy hh:mm:ss" format in local time zone, "--/--/--" if failed
   */
  parseLVIString(isoValue: string): string {
    if (!Date.parse(isoValue)) return '--/--/--';
    let date = new Date(isoValue);
    return (
      this.twoDigit(date.getDate()) +
      '/' +
      this.twoDigit(date.getMonth() + 1) +
      '/' +
      date.getFullYear() +
      ' ' +
      this.twoDigit(date.getHours()) +
      ':' +
      this.twoDigit(date.getMinutes()) +
      ':' +
      this.twoDigit(date.getSeconds())
    );
  }

  /**
   * Convert time to 2-digit number
   * @param number a number in time format
   * @returns 2-digit number with string type
   */
  twoDigit(number: number) {
    return ('0' + number.toString()).slice(-2);
  }
}
